<template>
	<mw-dialog
		v-model="dialog"
		small
		submitBtn
		cancelBtn
		@submit="submit"
		:title="$t('assessment.revoke')"
	>
		<template v-slot:button>
			<v-tooltip v-if="icon" left>
				<template v-slot:activator="{on}" >

				<v-btn v-on="on" icon @click="dialog = true">
					<v-icon v-if="icon">mdi-account-off</v-icon>
				</v-btn>
				</template>
				Revoke colleague access
			</v-tooltip>
			<v-list-item v-else @click="dialog = true">
				<v-list-item-title>
					{{ $t("buttons.revoke") }}
				</v-list-item-title>
			</v-list-item>
		</template>
		<template v-slot:dialog-body>
			<p >{{ $t("messages.before_revoke", {name: users[currentDelegate] ? users[currentDelegate].name : 'your colleague'}) }}</p>
		</template>
	</mw-dialog>
</template>

<style lang="less">
.button {
	cursor: pointer;
}
</style>
<script>
import MwDialog from "@c/ui/MwDialog";

export default {
	name: "Revoke",
	props: {
		id: { type: String }, // the ID of the response,
		icon: { type: Boolean, default: false },
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		response() {
			return this.$store.state.sectionResponses.data[this.id];
		},
        currentDelegate(){
			return this.$store.getters.currentDelegateForSection(this.id)
        }, 
        users(){
            return this.$store.state.users.data
        }
	},
	components: {
		MwDialog,
	},
	methods: {
		submit() {
			this.$store.dispatch( "revokeFrom", {response: this.id, user: this.currentDelegate } )
		},
	},
};
</script>
